import React from 'react'

import Layout from '../components/layout'
import Meta from '../components/meta'

const ComingSoonPage = () => (
  <>
    <Meta
      title="Coming Soon"
    />
    <Layout>
      <div className="typography-page limiter short-page">
        <h2>
          The Help Center is coming soon.
          <br/>
          Stay tuned!
          <br/><br/>
          Need help now? Contact support at <a href="mailto:support@screentray.com">support@screentray.com</a>.
        </h2>
      </div>
    </Layout>
  </>
)

export default ComingSoonPage
